import {
    applicantGenericFieldsList,
    genericFormFieldNames,
} from "@mb-react/krg-getid-form-data-common";
import {
    addToAppStore,
    getFormValues,
    getDictionaryByKey,
    hasValidationErrors,
    previousStep, getFromAppStore, removeAllValuesFromForm
} from "@mb-react/mb-react-core";
import {Button, Dialog, Message, NavButtons} from "@mb-react/mb-react-core";
import {
    extractFormValuesToSingleApplicantData,
    formFieldNames,
} from "@mb-react/svarog-ui";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {I18n, Translate} from "react-redux-i18n";
import {REDIRECT_TO_APPOINTMENT, ROOT} from "../../../../../constants/environment";
import {getPath, i18nl, TRG} from "../../../../../constants/i18n";
import {prepareServerStringDate} from "../../../../../sagas/generators/sagaUtil";
import {dictionaryReference, projectDictionaries, enrollmentStatus} from "../../../../../constants/general";
import {getLocalSelectOptionTranslation} from "../../../../componentUtil";
import {forms, navigationName} from "../../../../../constants/form";
import {preparePersonForTable} from "../../../../../util/environment";
import {
    isUseCaseChangeOwnership,
    checkIfEntityIsAlreadyEnrolled,
    isOperationModeProtected
} from "../../../../../util/businesslogicUtil";
import {LEGAL_ENTITY, NATURAL_PERSON} from "../../../../../constants/application";
import {generateID} from "../../../../../util/util";
import {onCancel} from "../../../../../util/componentUtil";
import NavButtonsLocal from "../../../../reactCoreComponents/NavButtonsLocal";

const PersonSummaryTaskWrapper = (props) => {
    let formValuesApplication = useSelector((state) => getFormValues(state, forms.APPLICATION))
    let formValuesPersonalInformation = useSelector((state) => getFormValues(state, forms.PERSONAL_INFORMATION + props.formNameSufix))
    let formValuesAddressData = useSelector((state) => getFormValues(state, forms.ADDRESS_DATA + props.formNameSufix))
    let formValuesLEData = useSelector((state) => getFormValues(state, forms.LEGAL_ENTITY + props.formNameSufix))
    const isInformationFormValid = useSelector((state) => hasValidationErrors(state, forms.PERSONAL_INFORMATION + props.formNameSufix));
    const isAddressFormValid = useSelector((state) => hasValidationErrors(state, forms.ADDRESS_DATA + props.formNameSufix));
    const isLegalEntityFormValid = useSelector((state) => hasValidationErrors(state, forms.LEGAL_ENTITY + props.formNameSufix))
    const listOfSellers = useSelector((state) => getFromAppStore(state, 'listOfSellers'))
    const totalPercentageSellers = useSelector((state) => getFromAppStore(state, 'totalPercentageSellers'))
    const [showDraftDialog, setShowDraftDialog] = useState(false);
    const [showEntityNotUniqueDialog, setShowEntityNotUniqueDialog] = useState(false);
    let formValues = {...formValuesPersonalInformation, ...formValuesAddressData, ...formValuesApplication, ...formValuesLEData}

    const provinceOfRegistrationOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.PROVINCES))
    const cityOfRegistrationOptions = useSelector((state) => getDictionaryByKey(state, dictionaryReference.CITIES_OF_REGISTRATION))
    const villageOfRegistrationOptions = useSelector((state) => getDictionaryByKey(state, dictionaryReference.VILLAGES_OF_REGISTRATION))
    const countryOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.COUNTRY_CODES))
    const provincesOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.PROVINCES))
    const cityOptions = useSelector((state) => getDictionaryByKey(state, dictionaryReference.CITIES_PLACE_OF_BIRTH))
    const villageOptions = useSelector((state) => getDictionaryByKey(state, dictionaryReference.VILLAGES_PLACE_OF_BIRTH))
    const provincesAddressOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.PROVINCES))
    const cityAddressOptions = useSelector((state) => getDictionaryByKey(state, dictionaryReference.CITIES_ADDRESS))
    const infoCenterAddressOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.INFO))
    const villageAddressOptions = useSelector((state) => getDictionaryByKey(state, dictionaryReference.VILLAGES_ADDRESS))
    const buildingClassAddressOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.BUILDING_CLASS))
    const genderOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.GENDER_CODES))
    const maritalOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.MARITAL_CODES))
    const foodCenterOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.FOOD_CENTER_INDICATOR))
    const cateringCenterProvinceOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.PROVINCES))
    const cateringCenterNumberOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.FOOD))
    const professionOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.PROFESSION))
    const nationalityOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.NATIONALITIES))
    const bloodGroupOptions = useSelector((state) => getDictionaryByKey(state, projectDictionaries.BLOOD_GROUP))
    const dispatch = useDispatch();

    const isIdentityLegalEntity = useSelector((state) =>
        getFromAppStore(state, "isIdentityLegalEntity")
    );

    const dynamicColumns = Object.keys(formValues).map((x, i) => {
        if (x === "applicationType") return;

        return formValues[x] && <div className="grid IDsummary" key={i}>
            <div className="col-12 xl:col-6">
                {I18n.t("label." + x)}
            </div>
            <div className="col-12 xl:col-6 summaryValue">
                {decorateField(x, formValues[x])}
            </div>
        </div>
    });

    function decorateField(formLabel, formValue) {
        switch (formLabel) {
            case genericFormFieldNames.PROVINCE_OF_REGISTRATION:
                return getLocalSelectOptionTranslation(provinceOfRegistrationOptions, formValue)
            case genericFormFieldNames.CITY_OF_REGISTRATION:
                return getLocalSelectOptionTranslation(cityOfRegistrationOptions, formValue)
            case genericFormFieldNames.VILLAGE_OF_REGISTRATION:
                return getLocalSelectOptionTranslation(villageOfRegistrationOptions, formValue)
            case formFieldNames.PLACE_OF_BIRTH_COUNTRY:
                return getLocalSelectOptionTranslation(countryOptions, formValue)
            case formFieldNames.PLACE_OF_BIRTH_PROVINCE:
                return getLocalSelectOptionTranslation(provincesOptions, formValue)
            case formFieldNames.PLACE_OF_BIRTH_CITY:
                return getLocalSelectOptionTranslation(cityOptions, formValue)
            case genericFormFieldNames.PLACE_OF_BIRTH_VILLAGE:
                return getLocalSelectOptionTranslation(villageOptions, formValue)
            case formFieldNames.ADDRESS_PROVINCE:
                return getLocalSelectOptionTranslation(provincesAddressOptions, formValue)
            case formFieldNames.ADDRESS_CITY:
                return getLocalSelectOptionTranslation(cityAddressOptions, formValue)
            case genericFormFieldNames.ADDRESS_VILLAGE:
                return getLocalSelectOptionTranslation(villageAddressOptions, formValue)
            case genericFormFieldNames.ADDRESS_INFORMATION_CENTER:
                return getLocalSelectOptionTranslation(infoCenterAddressOptions, formValue)
            case genericFormFieldNames.ADDRESS_BUILDING_CLASS:
                return getLocalSelectOptionTranslation(buildingClassAddressOptions, formValue)
            case formFieldNames.GENDER:
                return getLocalSelectOptionTranslation(genderOptions, formValue)
            case formFieldNames.MARITAL_STATUS:
                return getLocalSelectOptionTranslation(maritalOptions, formValue)
            case genericFormFieldNames.FOOD_CENTER_INDICATOR:
                const selectedOption = getLocalSelectOptionTranslation(foodCenterOptions, formValue);
                return selectedOption === "NO" ? "NO" : "YES";
            case genericFormFieldNames.CATERING_CENTER_PROVINCE:
                return getLocalSelectOptionTranslation(cateringCenterProvinceOptions, formValue)
            case genericFormFieldNames.CATERING_CENTER_NUMBER_NAME:
                return getLocalSelectOptionTranslation(cateringCenterNumberOptions, formValue)
            case formFieldNames.POSITION:
                return getLocalSelectOptionTranslation(professionOptions, formValue)
            case formFieldNames.NATIONALITY:
                return getLocalSelectOptionTranslation(nationalityOptions, formValue)
            case formFieldNames.BLOOD_GROUP:
                return getLocalSelectOptionTranslation(bloodGroupOptions, formValue)
            case "enrolmentStarted":
                return prepareServerStringDate(formValue)
            case "yearOfEstablishment":
                return formValue ? formValue.getFullYear() : null
            default:
                return typeof formValue == "string" || typeof formValue == "number" ? formValue : prepareServerStringDate(formValue)
        }
    }

    const isFormComplete = () => {
        if ((isIdentityLegalEntity && isLegalEntityFormValid) || (!isIdentityLegalEntity && (isAddressFormValid || isInformationFormValid))) {
            return false
        }
        return true
    };

    const addPerson = (state) => {
        let role = isUseCaseChangeOwnership(formValuesApplication) ? "SELLER" : null;
        let totalSumObject = {
          id: generateID(),
          lead: formValues.lead,
          percentage: formValues.percent,
        };
        totalPercentageSellers.push(totalSumObject)

        let requestObject = extractFormValuesToSingleApplicantData({
            ...formValues,
            [formFieldNames.APPLICATION_STATUS]: state,
            [formFieldNames.OWNER_ROLE]: role,
            [formFieldNames.APPLICANT_TYPE]: isIdentityLegalEntity ? LEGAL_ENTITY : NATURAL_PERSON,
            "enrollmentStatus": state
        }, [...applicantGenericFieldsList, "enrollmentStatus"])
        dispatch(addToAppStore('selectedPerson', requestObject))

        listOfSellers.push({
            sellerId: totalSumObject.id,
            enrollmentStatus: state,
            entityType: isIdentityLegalEntity ? LEGAL_ENTITY : NATURAL_PERSON,
            applicantData: requestObject
        })
        props.setPersonsTableData(preparePersonForTable(listOfSellers, totalPercentageSellers))
        dispatch(removeAllValuesFromForm(forms.PERSONAL_INFORMATION + props.formNameSufix))
        dispatch(removeAllValuesFromForm(forms.ADDRESS_DATA  + props.formNameSufix))
        dispatch(removeAllValuesFromForm(forms.LEGAL_ENTITY  + props.formNameSufix))

        setShowDraftDialog(false)
        dispatch(addToAppStore('isIdentityLegalEntity', false))
        dispatch(addToAppStore('showAddPersonForm', false))
        dispatch(addToAppStore('addIdentityToForm', false))

    };

    function addPersonToStore() {
        if(checkIfEntityIsAlreadyEnrolled(listOfSellers, {...formValuesPersonalInformation, ...formValuesLEData}, isIdentityLegalEntity)){
            setShowEntityNotUniqueDialog(true)
        }else if (isFormComplete()) {
            addPerson(enrollmentStatus.COMPLETE)
        } else {
            setShowDraftDialog(true)
        }
    }

    function createDraftApplication() {
        addPerson(enrollmentStatus.INCOMPLETE)
    }

    const renderDraftFooter = () => {
        return (
            <div>
                <Button label={I18n.t("label.draft")} icon="pi pi-edit" onClick={() => createDraftApplication()}
                        className="p-button-text"/>
                <Button label={I18n.t("label.continue")} icon="pi pi-arrow" onClick={() => {
                    dispatch(addToAppStore('validationMode', "Hard"))
                    setShowDraftDialog(false)
                }} className="p-button-text"/>
            </div>
        );
    };

    const renderEntityNotUniqueFooter = () => {
        return (
            <div>
                <Button label={I18n.t("action.cancelEnrollment")} icon="pi pi-arrow" onClick={() => {
                    setShowEntityNotUniqueDialog(false)
                    dispatch(removeAllValuesFromForm(forms.PERSONAL_INFORMATION + props.formNameSufix))
                    dispatch(removeAllValuesFromForm(forms.ADDRESS_DATA  + props.formNameSufix))
                    dispatch(removeAllValuesFromForm(forms.LEGAL_ENTITY  + props.formNameSufix))

                    dispatch(addToAppStore('isIdentityLegalEntity', false))
                    dispatch(addToAppStore('showAddPersonForm', false))
                    dispatch(addToAppStore('addIdentityToForm', false))
                }} className="p-button-text"/>
                <Button label={I18n.t("action.alterData")} icon="pi pi-arrow" onClick={() => {
                    setShowEntityNotUniqueDialog(false)
                }} className="p-button-text"/>
            </div>
        );
    };

    const renderApplicationModalFooter = () => {
        return (
            <div>
                <Button
                    label={I18n.t("label.Home")}
                    icon="pi pi-home"
                    onClick={() => {
                        window.location = ROOT + "/"
                    }}
                    className="p-button"
                />
                <Button
                    label={I18n.t("label.bookAppointment")}
                    icon="pi pi-calendar-plus"
                    onClick={startBookApp}
                    className="p-button"
                />
            </div>
        );
    };

    const startBookApp = () => {
        sessionStorage.setItem("selsectedApplicationId", formValues[formFieldNames.APPLICATION_ID]);
        window.location = REDIRECT_TO_APPOINTMENT
    };

    const onPrevious = () => {
        dispatch(previousStep(props.navigationName))
    }

    return (
        <div className="card summary">
            <Dialog header={I18n.t(getPath(i18nl.DRAFT, TRG.MESSAGE))} visible={false}
                    style={{width: '54vw'}} footer={renderApplicationModalFooter}>
                <Message severity="info" text={I18n.t("message.applicationSuccessfullySubmitted")}/>
            </Dialog>
            <div className="grid">
                {formValues && formValues.capturedFaceData &&
                <div className="col-12 md:col-3 xl:col-3">
                    <h5><Translate value="label.faceImage"/></h5>
                    <hr className="sectionSeparator"/>
                    <div className="text-center">
                        <img alt={"uploadedFace"} role="presentation"
                             src={"data:image/jpg;base64," + formValues.capturedFaceData.image} width={100}/>
                    </div>
                </div>
                }
                <div className={formValues && formValues.capturedFaceData ? "col-12 md:col-9 xl:col-9 p-2" : 'col-12'}>
                    <h5><Translate value="label.applicationData"/></h5>
                    <hr className="sectionSeparator"/>
                    {dynamicColumns}
                </div>
            </div>
            <Dialog header={I18n.t(getPath(i18nl.DRAFT, TRG.MESSAGE))} visible={showDraftDialog} style={{width: '54vw'}}
                    footer={renderDraftFooter} onHide={() => setShowDraftDialog(false)}>
                <Message severity="info" text={I18n.t(getPath(i18nl.APPLICATION_NOT_COMPLETE, TRG.MESSAGE))}/>
            </Dialog>
            <Dialog header={I18n.t(getPath(i18nl.ENTITY_NOT_UNIQUE_MODAL, TRG.MESSAGE))} visible={showEntityNotUniqueDialog} style={{width: '54vw'}}
                    footer={renderEntityNotUniqueFooter} onHide={() => setShowEntityNotUniqueDialog(false)}>
                <Message severity="info" text={I18n.t(getPath(i18nl.ENTITY_NOT_UNIQUE, TRG.MESSAGE))}/>
            </Dialog>
            <NavButtonsLocal customOnPrevious={() => onPrevious()}
                             lastStep
                             finalSubmitFunction={() => addPersonToStore()}
                            customOnCancel={() => onCancel(dispatch)}
                             hideDraft={true}/>
        </div>

    )
};
export default PersonSummaryTaskWrapper