import React, {useEffect, useMemo, useState} from "react";
import {
    Button,
    fieldType,
    FormElement,
    getFormValues,
    getFromAppStore,
    Captcha, showSpinner, showToast, toastIDs, addToAppStore, hideSpinner, addInitialValueToForm
} from "@mb-react/mb-react-core";
import {I18n, Translate} from "react-redux-i18n";
import {genericFormFieldNames, PlateNumberData, OldPlateData} from "@mb-react/krg-getid-form-data-common";
import {useDispatch, useSelector} from "react-redux";
import {forms} from "../../../../../constants/form";
import {formFieldNames} from "@mb-react/svarog-ui";
import {useHandleCommonServices} from "../../../../../services/commonServiceHandler";
import {
    isOperationModeNotProtected,
    isOperationModeProtected,
    isUseCaseReRegisterVehicle
} from "../../../../../util/businesslogicUtil";
import {usePlateNumberDataSettings} from "./plateDataSettings/plateNumberDataSettings";
import {useOldPlateDataSettings} from "./plateDataSettings/oldPlateDataSettings";
import {useHandleAccountServices} from "../../../../../services/account/accountServiceHandler";
import {restErrors} from "../../../../../constants/general";
import {useForm} from "react-final-form";
import {extractGenericFields} from "../../../../../util/formUtils";
import {LEGAL_ENTITY, NATURAL_PERSON} from "../../../../../constants/application";

const OldPlateTask = (formName) => {
    const dispatch = useDispatch();
    const handleCommonService = useHandleCommonServices();
    const formValuesApplication = useSelector((state) => getFormValues(state, forms.APPLICATION));
    const formValuesPlateData = useSelector((state) => getFormValues(state, forms.PLATE_DATA));
    let formValues = {...formValuesApplication, ...formValuesPlateData}
    const plateNumberDataSettings = usePlateNumberDataSettings(formName);
    const reservePlateNumberButton = useSelector((state) => getFromAppStore(state, "reservePlateNumberButton"));
    const oldPlateDataSettings = useOldPlateDataSettings(formName)
    const desiredPlateNumberReserved = useSelector((state) => getFromAppStore(state, "desiredPlateNumberReserved"));
    const addedApplicants = useSelector((state) => getFromAppStore(state, 'listOfSellers'))
    const isLegalEntity = addedApplicants ? useMemo(() => addedApplicants.some(a => a?.entityType === LEGAL_ENTITY), [addedApplicants?.length]) : [];
    const leType = addedApplicants ? useMemo(() => addedApplicants.find(a => a?.entityType === LEGAL_ENTITY)?.applicantData?.legalEntityDTO?.[formFieldNames.LE_TYPE], [addedApplicants?.length]) : [];
    let form = useForm();
    const successfullyReRegisterOldPlateNumber = useSelector((state) => getFromAppStore(state, "SUCCESSFULLY_RE_REGISTER_OLD_PLATE_NUMBER")) ?? false;

    let desiredPlateCss = isOperationModeProtected() ? "flex justify-content-center protectedWrap" : "publicWrap"

    //captcha data
    const [captchaData, setCaptchaData] = useState();
    const [captchaUrl, setCaptchaUrl] = useState(null);
    const handleAccountServices = useHandleAccountServices();
    const [captchaRender, setCaptchaRender] = useState(false);

    useEffect(() => {
    }, []);

    const prepareCaptchaImage = (data) => {
        if (data != null) {
            return "data:image/jpg;base64," + data.base64;
        }
        return null;
    };

    const prepareCaptcha = (data) => {
        if (data) {
            setCaptchaData(data);
            setCaptchaUrl(prepareCaptchaImage(data));
        }
    }

    const getCaptcha = () => {
        handleAccountServices.handleGetCaptcha(prepareCaptcha)
    };

    const handleResponse = () => {
        //TODO proper handling of error codes
        dispatch(showToast(toastIDs.MESSAGE_TOAST, 'error', {messageId: I18n.t("err." + restErrors.ERROR_WRONG_CAPTCHA)}))
        form.change(formFieldNames.CAPTCHA, '')
        getCaptcha()
    };

    const allocatePlateNumber = () => {
        if (isOperationModeNotProtected()) {
            const params = {
                captcha: formValuesPlateData.captcha,
                captchaId: captchaData.id,
            };
            handleAccountServices.handleCheckCaptcha(params, onAllocateNumberCheckCaptchaSuccess, handleResponse)
        } else {
            onAllocateNumberCheckCaptchaSuccess()
        }
    }

    const allocatePlateNumberOnSuccess = (plateNumber) => {
        dispatch(addToAppStore('reservePlateNumberButton', true))

        form.change(genericFormFieldNames.DESIRED_PLATE_NUMBER_LATIN, plateNumber)

        let regex = /^[A-Z]/;

        if (isUseCaseReRegisterVehicle(formValues) && formValuesPlateData[formFieldNames.PLATE_PROVINCE] &&
            plateNumber && plateNumber.match(regex)) {

            let letter = plateNumber.substring(0, 1);
            let number = plateNumber.substring(1);

            plateNumber = formValuesPlateData[formFieldNames.PLATE_PROVINCE] + " " + letter + " " + number;
        }
        form.change(formFieldNames.PLATE_NUMBER, plateNumber);
        form.change(formFieldNames.PLATE_NUMBER_LATIN, plateNumber);
        form.change(genericFormFieldNames.PLATE_RESERVATION_COMPLETED, true);
    };

    const onAllocateNumberCheckCaptchaSuccess = () => {
        form.change(genericFormFieldNames.PLATE_REFERENCE_NUMBER, formValues[formFieldNames.APPLICATION_ID])
        handleCommonService.handleAllocateOldPlateNumber(formValuesPlateData?.[genericFormFieldNames.OLD_PLATE_NUMBER_LATIN], allocatePlateNumberOnSuccess);
        getCaptcha()
    }

    const preparePlateGenerics = () => {
        let generics = extractGenericFields(formValues, [genericFormFieldNames.DESIRED_PLATE_NUMBER_LATIN]);
        generics?.genericFieldDTOList.push(
            {
                key: "applicantType",
                value: isLegalEntity ? LEGAL_ENTITY : NATURAL_PERSON
            },
            {
                key: "applicantSubType",
                value: leType ?? null
            });

        return generics;
    };

    const plateRequestData = {
        [formFieldNames.PLATE_CATEGORY]: formValues?.[formFieldNames.PLATE_CATEGORY],
        [formFieldNames.PLATE_USAGE]: formValues?.[formFieldNames.PLATE_USAGE],
        [formFieldNames.PLATE_PROVINCE]: formValues?.[formFieldNames.PLATE_PROVINCE],
        [formFieldNames.PLATE_COUNTRY_CODE]: formValues?.[formFieldNames.PLATE_COUNTRY_CODE],
        genericFieldsDTO: preparePlateGenerics()
    };

    const handleGetPlateReservationUseAndReferenceSuccess = (response) => {
        dispatch(hideSpinner())
        if (response) {
            dispatch(addToAppStore('desiredPlateNumberReserved', true))
            dispatch(showToast(toastIDs.MESSAGE_TOAST, 'success', {messageId: I18n.t("message.plateSuccessfullyReserved")}))
            dispatch(addToAppStore('plateReserved', true))
            dispatch(addToAppStore(genericFormFieldNames.RESERVED_FOR_RE_REGISTER, true));
            dispatch(addToAppStore("SUCCESSFULLY_RE_REGISTER_OLD_PLATE_NUMBER", true));
            form.change(genericFormFieldNames.RESERVED_FOR_RE_REGISTER, true);
            dispatch(addToAppStore("existingReservationReference", response?.plateReservationReference));
            form.change(genericFormFieldNames.PLATE_REFERENCE_NUMBER, response?.plateReservationReference)
        } else {
            dispatch(showToast(toastIDs.MESSAGE_TOAST, 'error', {messageId: I18n.t("err.plateReservationExpected")}))
        }
    }

    const handleGetPlateReservationUseAndReferenceError = () => {
        dispatch(showToast(toastIDs.MESSAGE_TOAST, 'error', {messageId: I18n.t("err.plateReservationExpected")}))
        dispatch(hideSpinner())
    }

    const getPlateReservationUseAndReference = () => {
        dispatch(showSpinner(I18n.t('message.getPlateReservationUseAndReference')))
        handleCommonService.handleGetPlateReservationUseAndReference(plateRequestData, handleGetPlateReservationUseAndReferenceSuccess, handleGetPlateReservationUseAndReferenceError);
    }

    return (
        <div className="grid">
            <div className="grid">
                <div className="col-12">
                    <FormElement
                        label={"label." + genericFormFieldNames.RESERVED_FOR_RE_REGISTER}
                        fieldType={fieldType.CHECKBOX_CUSTOM}
                        fieldProps={{
                            name: "RE_REGISTER_OLD_PLATE_NUMBER",
                            readOnly: true
                        }}
                    />
                </div>
                <div className={"col-12"}>
                    <h5 className="oldVehiclePlateDetailsTaskLabel"><Translate
                        value="label.oldVehiclePlateDetailsTask"/></h5>
                    <div className="grid">
                        <OldPlateData {...oldPlateDataSettings} />
                    </div>
                </div>
                {!captchaRender &&
                    <div
                        className={"col-12 flex justify-content-center captchaAndPlateWrap"}>
                        {isOperationModeNotProtected() && <div className={"col-6"}>
                            <Captcha
                                captchaURL={captchaUrl}
                                fieldName={formFieldNames.CAPTCHA}
                                onRefreshCaptcha={() => getCaptcha()}
                                captchaPlaceholder={false}
                            />
                        </div>}
                        <div className={"col-6"}>
                            <div className={desiredPlateCss}>
                                <PlateNumberData {...plateNumberDataSettings} />
                            </div>
                        </div>
                    </div>}
                <div className={"col-12 flex justify-content-center"}>
                    <div className="col-6 checkPlateBtn">
                        <Button label={I18n.t("action.allocate")}
                                disabled={!formValuesPlateData?.[genericFormFieldNames.OLD_PLATE_NUMBER_LATIN] || successfullyReRegisterOldPlateNumber}
                                className="p-button-success"
                                type="button"
                                onClick={() => allocatePlateNumber()}/>
                    </div>
                    <div className="col-6 checkPlateNum">
                        <Button label={I18n.t("action.reservePlateNumber")}
                                disabled={!reservePlateNumberButton || successfullyReRegisterOldPlateNumber}
                                className="p-button-secondary"
                                type="button"
                                onClick={() => getPlateReservationUseAndReference()}/>
                    </div>
                    {!isUseCaseReRegisterVehicle(formValues) &&
                        <div className="grid auctionNumberWrap">
                            <FormElement
                                label={I18n.t('label.useAuctionNumber')}
                                fieldType={fieldType.CHECKBOX_CUSTOM}
                                additionalClass={"checkBoxQuestLeft"}
                                fieldProps={{
                                    name: "useAuctionNumber",
                                    readOnly: desiredPlateNumberReserved
                                }}
                            />
                        </div>}
                </div>
            </div>
        </div>
    );
};
export default OldPlateTask;