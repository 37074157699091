import { genericFormFieldNames } from "@mb-react/krg-getid-form-data-common"
import { formFieldNames } from "@mb-react/svarog-ui"
import { isFoodCenter, isInsideCity, isIRQ, isMarried, isOutsideCity, isUnemployed } from "./businesslogicUtil"
import {changeOwnershipSubUseCases, vehicleTypes} from "../constants/application";

export function specificMandatoryBehavior(formValues, fieldName, useCase, documentType, workflow, defaultValue) {
    switch (fieldName) {
        //TODO: OLD_PLATE_SERIAL_NUMBER should be available for Embassy Vehicles.
        // genericFormFieldNames.OLD_PLATE_SERIAL_NUMBER

        case formFieldNames.PLACE_OF_BIRTH_PROVINCE:
        case formFieldNames.PLACE_OF_BIRTH_CITY:
        case genericFormFieldNames.PLACE_OF_BIRTH_VILLAGE:
            return formValues && formValues[formFieldNames.PLACE_OF_BIRTH_COUNTRY] && isIRQ(formValues[formFieldNames.PLACE_OF_BIRTH_COUNTRY]);

        case formFieldNames.SPOUSE_NAME:
        case genericFormFieldNames.SPOUSE_FATHER_NAME:
        case genericFormFieldNames.SPOUSE_GRANDFATHER_NAME:
            return isMarried(formValues[formFieldNames.MARITAL_STATUS]);

        case genericFormFieldNames.CATERING_CENTER_NUMBER_NAME:
        case genericFormFieldNames.CATERING_CENTER_PROVINCE:
        case genericFormFieldNames.FOOD_RATION_CARD_NUMBER:
        case genericFormFieldNames.FOOD_RATION_CARD_ISSUE_DATE:
            return isFoodCenter(formValues);

        case genericFormFieldNames.NUMBER_DL:
        case formFieldNames.ADDRESS_STREET_NUMBER:
        case formFieldNames.ADDRESS_HOUSE_NUMBER:
        case genericFormFieldNames.QUARTER_NAME:
        case genericFormFieldNames.SECTOR_NUMBER:
        case genericFormFieldNames.RESIDENCE_CARD_NUMBER:
        // case genericFormFieldNames.RESIDENCE_CARD_DATE_OF_ISSUE:
        //     return isInsideCity(formValues)
        //         && !isUseCaseUncancelVR(formValues) && !isUseCaseExpiredVR(formValues)
        //         && !isUseCaseCancelVR(formValues) && !isUseCaseUnregisterVR(formValues)
            return defaultValue;

        case genericFormFieldNames.PROVINCE_OF_REGISTRATION:
        case genericFormFieldNames.CITY_OF_REGISTRATION:
        case genericFormFieldNames.VILLAGE_OF_REGISTRATION:
        case genericFormFieldNames.REGISTRATION_BOOK_NUMBER:
        case genericFormFieldNames.REGISTRATION_PAGE_NUMBER:
            return formValues[formFieldNames.NATIONALITY] && isIRQ(formValues[formFieldNames.NATIONALITY]);
        case genericFormFieldNames.VILLAGE_REGION:
        case genericFormFieldNames.RESIDENCE_CERTIFICATE_NUMBER:
        // case genericFormFieldNames.RESIDENCE_CERTIFICATE_DATE_OF_ISSUE:
        //     return isOutsideCity(formValues)
        //         && !isUseCaseUncancelVR(formValues) && !isUseCaseExpiredVR(formValues)
        //         && !isUseCaseCancelVR(formValues) && !isUseCaseUnregisterVR(formValues)

        case formFieldNames.OWNERSHIP_PERCENTAGE:
            return formValues[formFieldNames.SHARED_OWNERSHIP] === true;

        case genericFormFieldNames.NUMBER_OF_REQUESTED_PLATES:
        case genericFormFieldNames.EXISTING_PLATES:
            return formValues[genericFormFieldNames.NEW_PLATES] === true

        case genericFormFieldNames.PREVIOUS_CANCELLATION_COMMENT:
            return formValues[genericFormFieldNames.PREVIOUS_CANCELLATION] === true;

        case genericFormFieldNames.TEMPORARY_CANCELLATION_NUMBER_OF_MONTHS:
            return formValues[genericFormFieldNames.CANCELLATION_TYPE] === "TEMPORARY";

        case formFieldNames.EMPLOYER_OR_SCHOOL:
            return !isUnemployed(formValues);

        case genericFormFieldNames.RESIDENCE_PERMIT_NUMBER:
        case genericFormFieldNames.RESIDENCE_PERMIT_ISSUE_DATE:
            return formValues[formFieldNames.NATIONALITY] && !isIRQ(formValues[formFieldNames.NATIONALITY]);
        case formFieldNames.SEATS:
        case formFieldNames.FUEL:
        case formFieldNames.CABIN_TYPE:
            return true;
        case formFieldNames.VEHICLE_BRAND:
        case formFieldNames.VEHICLE_MODEL:
        case formFieldNames.PRODUCTION_YEAR:
        case formFieldNames.VEHICLE_COLOR:
        case formFieldNames.CABIN_NUMBER:
        case formFieldNames.CYLINDERS:
            return formValues[genericFormFieldNames.VEHICLE_TYPE] === vehicleTypes.VEHICLE;
        case formFieldNames.TOTAL_WEIGHT:
        case genericFormFieldNames.AXIS:
            return formValues[genericFormFieldNames.VEHICLE_TYPE] === vehicleTypes.TRAILER;
        case genericFormFieldNames.OLD_VEHICLE_REGISTRATION_NUMBER:
            return !formValues?.[genericFormFieldNames.OWNS_PREVIOUS_VR];
        case formFieldNames.LE_NAME:
        case formFieldNames.LE_TYPE:
        case formFieldNames.LE_SUBTYPE:
        case formFieldNames.TPID_PARENT:
        case formFieldNames.LE_ADDRESS_COUNTRY:
        case formFieldNames.LE_ADDRESS_PROVINCE:
        case formFieldNames.LE_ADDRESS_CITY:
        case formFieldNames.LE_ADDRESS_REGION:
        case genericFormFieldNames.LE_ADDRESS_VILLAGE:
        case formFieldNames.LE_ADDRESS_STREET:
        case formFieldNames.LE_ADDRESS_HOUSE_NUMBER:
        case formFieldNames.LE_ADDRESS_ZIP_CODE:
        case formFieldNames.LE_QUARTER_NAME:
        case formFieldNames.LE_SECTOR_NUMBER:
        case formFieldNames.LE_STATE:
            //return formValues?.[formFieldNames.APPLICANT_TYPE] === LEGAL_ENTITY;
            return true
        case genericFormFieldNames.CONTRACT_DATE:
        case genericFormFieldNames.CONTRACT_NUMBER:
            return formValues?.["changeOwnershipSubUseCase"] === changeOwnershipSubUseCases.SALE_AND_BUY
        default:
            return defaultValue
    }
}

export function specificReadOnlyBehavior(formValues, fieldName, useCase, documentType, workflow, defaultValue) {
    switch (fieldName) {
        case formFieldNames.PLACE_OF_BIRTH_PROVINCE:
        case formFieldNames.PLACE_OF_BIRTH_CITY:
        case genericFormFieldNames.PLACE_OF_BIRTH_VILLAGE:

            if (formValues) {
                if (!formValues[formFieldNames.PLACE_OF_BIRTH_COUNTRY]) {
                    return true
                } else {
                    return !isIRQ(formValues[formFieldNames.PLACE_OF_BIRTH_COUNTRY])
                }
            } else {
                return true
            }
        case genericFormFieldNames.PROVINCE_OF_REGISTRATION:
        case genericFormFieldNames.CITY_OF_REGISTRATION:
        case genericFormFieldNames.VILLAGE_OF_REGISTRATION:
        case genericFormFieldNames.REGISTRATION_BOOK_NUMBER:
        case genericFormFieldNames.REGISTRATION_PAGE_NUMBER:
            if (formValues) {
                if (!formValues[formFieldNames.NATIONALITY]) {
                    return true
                } else {
                    return !isIRQ(formValues[formFieldNames.NATIONALITY])
                }
            } else {
                return true
            }
        case formFieldNames.SPOUSE_NAME:
        case genericFormFieldNames.SPOUSE_FATHER_NAME:
        case genericFormFieldNames.SPOUSE_GRANDFATHER_NAME:
            return !isMarried(formValues[formFieldNames.MARITAL_STATUS])
        case genericFormFieldNames.FOOD_CENTER_INDICATOR:
            return !isIRQ(formValues[formFieldNames.NATIONALITY]);

        case genericFormFieldNames.CATERING_CENTER_NUMBER_NAME:
        case genericFormFieldNames.CATERING_CENTER_PROVINCE:
        case genericFormFieldNames.FOOD_RATION_CARD_NUMBER:
        case genericFormFieldNames.FOOD_RATION_CARD_ISSUE_DATE:
            return !isFoodCenter(formValues)

        case formFieldNames.ADDRESS_STREET_NUMBER:
        case formFieldNames.ADDRESS_HOUSE_NUMBER:
        case genericFormFieldNames.QUARTER_NAME:
        case genericFormFieldNames.SECTOR_NUMBER:
        case genericFormFieldNames.RESIDENCE_CARD_NUMBER:
        case genericFormFieldNames.RESIDENCE_CARD_DATE_OF_ISSUE:
            return !isInsideCity(formValues)
        case genericFormFieldNames.ELDER_NAME:
        case genericFormFieldNames.VILLAGE_REGION:
        case genericFormFieldNames.RESIDENCE_CERTIFICATE_NUMBER:
        case genericFormFieldNames.RESIDENCE_CERTIFICATE_DATE_OF_ISSUE:
            return !isOutsideCity(formValues)

        case genericFormFieldNames.RESIDENCE_PERMIT_NUMBER:
        case genericFormFieldNames.RESIDENCE_PERMIT_ISSUE_DATE:
            return !formValues[formFieldNames.NATIONALITY] || isIRQ(formValues[formFieldNames.NATIONALITY])

        case genericFormFieldNames.VALID_DATE_DL:
            return formValues[genericFormFieldNames.ISSUE_DATE_DL] == undefined

        case formFieldNames.LE_SUBTYPE:
            return !["IO", "embassy"].includes(formValues?.[formFieldNames.LE_TYPE])
        default:
            return defaultValue
    }
}
