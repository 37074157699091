import React, {useEffect, useMemo, useRef, useState} from "react";
import {
    addToAppStore,
    Button,
    Captcha,
    fieldType,
    FormElement, getFromAppStore,
    Messages, showToast,
    toastIDs
} from "@mb-react/mb-react-core";
import {useDispatch, useSelector} from "react-redux";
import {I18n, Translate} from "react-redux-i18n";
import {applicationStore, VEHICLE_DATA_STEP} from "../../../../../constants/store";
import {useHandleWorkflowSettings} from "../../../../entries/application/handleWorkflowSettings";
import {
    genericFormFieldNames,
    PlateData,
    PlateNumberData,
    OldPlateData
} from "@mb-react/krg-getid-form-data-common";
import {getFormValues, TabPanel, TabView} from "@mb-react/mb-react-core";
import {forms} from "../../../../../constants/form";
import {EUseCases, formFieldNames, getCookie} from "@mb-react/svarog-ui";
import {usePlateDataSettings} from "./plateDataSettings/plateDataSettings";
import {restErrors, sourcePlateSelect, ACCOUNT_TYPE, filteredSourcePlateSelect} from "../../../../../constants/general";
import {usePlateNumberDataSettings} from "./plateDataSettings/plateNumberDataSettings";
import {
    disablePlateHandlingTask,
    getAccountLegalEntityType,
    getPlateCategoryValues,
    isLoggedAccountLegalEntity,
    isModificationVRUseCase,
    isOperationModeNotProtected,
    isOperationModeProtected,
    isSourcePlateChosen,
    isUseCaseChangeOwnership,
    isUseCaseReRegisterVehicle,
    prepareLETypeForDropDownList, renderFurtherUseSection, usePlateDataFromSystemForUCs
} from "../../../../../util/businesslogicUtil";
import {useHandleAccountServices} from "../../../../../services/account/accountServiceHandler";
import {useHandleCommonServices} from "../../../../../services/commonServiceHandler";
import {useForm} from "react-final-form";
import {projectDictionaries} from "../../../../../constants/dictionaries";
import {accountSubType, changeOwnershipSubUseCases, plateCategories} from "../../../../../constants/application";
import {useOldPlateDataSettings} from "./plateDataSettings/oldPlateDataSettings";
import ModifyProvinceSiteData from "./component/ModifyProvinceSiteData";
import {useModifyProvinceSiteData} from "./plateDataSettings/modifyProvinceSiteDataSettings";
import UseExistingPlateTask from "./UseExistingPlateTask";
import {formatPlateNumber, reservePlateNumberRequestData} from "../../../../../util/vrUtil";
import {removeLeadingZero} from "../../../../../util/dataPreparationUtil";
import OldPlateTask from "./OldPlateTask";

const PlateDataTask = (formName) => {
    let dispatch = useDispatch();
    const formValues = useSelector((state) => getFormValues(state, forms.APPLICATION));
    const useCaseFromStore = useSelector((state) => getFormValues(state, forms.APPLICATION)["useCase"]);
    const docTypeFromStore = useSelector((state) => getFormValues(state, forms.APPLICATION)["documentType"]);
    const formValuesPlateData = useSelector((state) => getFormValues(state, forms.PLATE_DATA));
    const userRights = useSelector(state => getFromAppStore(state, applicationStore.USER_RIGHTS))
    const handleWorkflowSettings = useHandleWorkflowSettings();
    const handleCommonService = useHandleCommonServices();
    const guidanceMessage1 = useRef(null);
    const guidanceMessageModifyProvince = useRef(null);
    const vehicleFromSystem = useSelector((state) => getFromAppStore(state, applicationStore.VEHICLE_FROM_SYSTEM));
    const plateDataHelper = useSelector((state) => getFromAppStore(state, applicationStore.PLATE_DATA_HELPER));

    //captcha data
    const [captchaData, setCaptchaData] = useState();
    const [captchaUrl, setCaptchaUrl] = useState(null);
    const handleAccountServices = useHandleAccountServices();
    const [captchaRender, setCaptchaRender] = useState(false);
    const displayDesiredPlateNumber = useMemo(() => userRights?.includes("desiredPlateNumber"), [userRights]);

    const shouldRenderTemporaryReservation = isPlateProvinceRequiredForTemporaryReservation() ?
        (formValuesPlateData[formFieldNames.PLATE_USAGE] && (formValuesPlateData[formFieldNames.PLATE_PROVINCE] || formValuesPlateData[formFieldNames.COUNTRY_CODE])) :
        formValuesPlateData[formFieldNames.PLATE_USAGE];

    let form = useForm();

    function isPlateProvinceRequiredForTemporaryReservation() {
        if (isLoggedAccountLegalEntity()
            && [accountSubType.GOVERNMENT, accountSubType.COMPANY, accountSubType.NATIONAL_ORGANIZATION].includes(getAccountLegalEntityType())) {
            if (plateCategories.OTHER === formValuesPlateData[formFieldNames.PLATE_CATEGORY]) {
                return false;
            } else if (plateCategories.MOTORCYCLE === formValuesPlateData[formFieldNames.PLATE_CATEGORY]
                && "private" !== formValuesPlateData[formFieldNames.PLATE_USAGE]) {
                return false;
            }
        }

        return true;
    }

    const reservePlateNumberButton = useSelector((state) =>
        getFromAppStore(state, "reservePlateNumberButton")
    );

    const desiredPlateNumberReserved = useSelector((state) =>
        getFromAppStore(state, "desiredPlateNumberReserved")
    )

    const checkAvailablePlateNumberButton = useSelector((state) =>
        getFromAppStore(state, "checkAvailablePlateNumberButton")
    );

    const isPlateReserved = useSelector(state => getFromAppStore(state, "plateReserved"));

    const isDisabledUnreservePlate = useMemo(() => {
        return !(desiredPlateNumberReserved && isPlateReserved);
    }, [desiredPlateNumberReserved, isPlateReserved])

    const unreservePlateNumber = () => {
        if (formValuesPlateData[formFieldNames.PLATE_NUMBER]) {
            let requestData = {
                applicationID: "",
                plateDTO: {
                    ...plateRequestData,
                    [formFieldNames.PLATE_NUMBER]: formatPlateNumber(formValuesPlateData[formFieldNames.PLATE_NUMBER])
                }
            }

            handleCommonService.handleReserveLicenseNumbers(requestData, () => {
                form.change(formFieldNames.PLATE_NUMBER, null);
                form.change(formFieldNames.PLATE_NUMBER_LATIN, null);
                form.change(genericFormFieldNames.DESIRED_PLATE_NUMBER_LATIN, null);
                dispatch(addToAppStore('reservePlateNumberButton', true))
                dispatch(addToAppStore('checkAvailablePlateNumberButton', false))
                dispatch(addToAppStore('desiredPlateNumberReserved', false))
                dispatch(addToAppStore('plateReserved', false))
                //Dear Lord...
                setCaptchaRender(false);
            }, reservePlateNumberLocalError)
        }
    }

    const prepareCaptchaImage = (data) => {
        if (data != null) {
            return "data:image/jpg;base64," + data.base64;
        }
        return null;
    };
    const prepareCaptcha = (data) => {
        if (data) {
            setCaptchaData(data);
            setCaptchaUrl(prepareCaptchaImage(data));
        }
    }
    const getCaptcha = () => {
        handleAccountServices.handleGetCaptcha(prepareCaptcha)
    };

    let applicantSubType = null;
    if (isLoggedAccountLegalEntity()) {
        applicantSubType = prepareLETypeForDropDownList(getAccountLegalEntityType());
        if (applicantSubType === "-") {
            applicantSubType = null;
        }
    }

    const plateRequestData = reservePlateNumberRequestData(formValuesPlateData, getCookie(ACCOUNT_TYPE), applicantSubType)

    const checkPlateReservation = () => {

        if (isOperationModeNotProtected()) {
            const params = {
                captcha: formValuesPlateData.captcha,
                captchaId: captchaData.id,
            };
            handleAccountServices.handleCheckCaptcha(params, onCheckCaptchaSuccess, handleResponse)
        } else {
            onCheckCaptchaSuccess()
        }
    }

    const reservePlateNumber = () => {

        if(isOperationModeNotProtected() && isUseCaseReRegisterVehicle(formValues) && formValuesPlateData["assignedPlateNumber"] && formValuesPlateData["assignedPlateNumber"] != ""){
            form.change(genericFormFieldNames.PLATE_REFERENCE_NUMBER, formValues[formFieldNames.APPLICATION_ID])
            dispatch(addToAppStore('reservePlateNumberButton', false))
            dispatch(addToAppStore('checkAvailablePlateNumberButton', true))
            dispatch(addToAppStore('desiredPlateNumberReserved', true))
            dispatch(showToast(toastIDs.MESSAGE_TOAST, 'success', {messageId: I18n.t("message.plateSuccessfullyReserved")}))
            dispatch(addToAppStore('plateReserved', true))
            setCaptchaRender(true)
        }else{
            if (formValuesPlateData[formFieldNames.PLATE_NUMBER]) {
                let plateNumberFormatted = formatPlateNumber(formValuesPlateData[formFieldNames.PLATE_NUMBER]);

                let requestData = {
                    applicationID: formValues["applicationID"],
                    plateDTO: {
                        ...plateRequestData,
                        [formFieldNames.PLATE_NUMBER]: plateNumberFormatted
                    }
                }

                handleCommonService.handleReserveLicenseNumbers(requestData, reservePlateNumberLocalSuccess, reservePlateNumberLocalError)
            }

        }
    }

    const reservePlateNumberLocalSuccess = (response) => {
        if (response == "ALREADY_RESERVED") {
            dispatch(addToAppStore('plateReserved', false))
            dispatch(showToast(toastIDs.MESSAGE_TOAST, 'warn', {messageId: I18n.t("err.plateAlreadyReserved")}))
        }

        if (response == "OK") {

            if (formValuesPlateData["useAuctionNumber"]) {
                form.change(genericFormFieldNames.AUCTION_REFERENCE_NUMBER, formValues[formFieldNames.APPLICATION_ID])
            } else {
                form.change(genericFormFieldNames.PLATE_REFERENCE_NUMBER, formValues[formFieldNames.APPLICATION_ID])
            }

            dispatch(addToAppStore('reservePlateNumberButton', false))
            dispatch(addToAppStore('checkAvailablePlateNumberButton', true))
            dispatch(addToAppStore('desiredPlateNumberReserved', true))
            dispatch(showToast(toastIDs.MESSAGE_TOAST, 'success', {messageId: I18n.t("message.plateSuccessfullyReserved")}))
            dispatch(addToAppStore('plateReserved', true))
            setCaptchaRender(true)
        }

    }

    const reservePlateNumberLocalError = (response) => {
        console.log("reservePlateNumberLocalError", response)
        dispatch(showToast(toastIDs.MESSAGE_TOAST, 'err', {messageId: I18n.t("err.plateErrUn/Reserved")}))
    };

    const handleResponse = () => {
        //TODO proper handling of error codes
        dispatch(showToast(toastIDs.MESSAGE_TOAST, 'error', {messageId: I18n.t("err." + restErrors.ERROR_WRONG_CAPTCHA)}))
        form.change(formFieldNames.CAPTCHA, '')
        getCaptcha()
    };

    const onCheckCaptchaSuccess = () => {
        handleCommonService.handleGetAvailableLicenseNumbers(plateRequestData, searchAvailablePlatesLocalSuccess, searchAvailablePlatesLocalError)
        getCaptcha()
    }

    const searchAvailablePlatesLocalSuccess = (availablePlates) => {
        if(!availablePlates || availablePlates.length < 1){
            dispatch(addToAppStore('plateReserved', false))
            form.change(genericFormFieldNames.DESIRED_PLATE_NUMBER_LATIN, null);
            form.change(formFieldNames.CAPTCHA, '')
            dispatch(showToast(toastIDs.MESSAGE_TOAST, 'warn', {messageId: I18n.t("err.plateNotAvailable")}))
        }else{
            form.change(formFieldNames.PLATE_NUMBER, availablePlates[0]);
            dispatch(addToAppStore('reservePlateNumberButton', true))
        }
    };

    const searchAvailablePlatesLocalError = (response) => {
        dispatch(addToAppStore('plateReserved', false))
        form.change(genericFormFieldNames.DESIRED_PLATE_NUMBER_LATIN, null);
        form.change(formFieldNames.CAPTCHA, '')
        dispatch(showToast(toastIDs.MESSAGE_TOAST, 'error', {messageId: I18n.t("err.searchAvailablePlateException")}))
    };

    useEffect(() => {
        guidanceMessage1.current.show({
            severity: 'info', sticky: true, content: (
                <React.Fragment className="mb-1">
                    <i className="pi pi-info p-2" style={{
                        borderRadius: '50%',
                        backgroundColor: 'var(--surface-b)',
                        color: 'var(--surface-d)'
                    }}/>
                    <div className="ml-2">
                        <Translate value="message.vehicleDataGuidanceMessage1"/>
                    </div>
                </React.Fragment>
            )
        });

        if (desiredPlateNumberReserved) {
            setCaptchaRender(true)
        }

        if (isOperationModeNotProtected()) {
            getCaptcha();
        }
        form.change("RE_REGISTER_OLD_PLATE_NUMBER", true);
    }, []);

    useEffect(() => {
        if(guidanceMessageModifyProvince.current){
            if(formValuesPlateData?.["modifyProvinceSite"]){
                guidanceMessageModifyProvince.current.show({
                    severity: 'warn', sticky: true, content: (
                        <React.Fragment className="mb-1">
                            <i className="pi pi-info p-2" style={{
                                borderRadius: '50%',
                                backgroundColor: 'var(--surface-b)',
                                color: 'var(--surface-d)'
                            }}/>
                            <div className="ml-2">
                                <Translate value="message.plateDataModifyProvinceGuidanceMessage"/>
                            </div>
                        </React.Fragment>
                    )
                });
            }else {
                guidanceMessageModifyProvince.current.clear();
            }
        }
    }, [formValuesPlateData?.["modifyProvinceSite"]]);

    useEffect(() => {

        if (formValuesPlateData) {
            if(formValuesPlateData[formFieldNames.PLATE_CATEGORY]){
                handleCommonService.handleGetSingleDictionary(projectDictionaries.PLATE_USAGE, formValuesPlateData[formFieldNames.PLATE_CATEGORY], projectDictionaries.PLATE_USAGE)
            }
        }
    }, [formValuesPlateData?.[formFieldNames.PLATE_CATEGORY]]);

    let sectionsToRender = handleWorkflowSettings.vehicleDataStepsToRender;

    const plateNumberDataSettings = usePlateNumberDataSettings(formName);
    const modifyProvinceSiteDataSettings = useModifyProvinceSiteData();
    const plateDataSettings = usePlateDataSettings(formName);
    const oldPlateDataSettings = useOldPlateDataSettings(formName)

    const onPlateTypeChange = (e) => {
            if(e.value && e.value === "FURTHER_USE" && plateDataHelper){
                form.change(formFieldNames.PLATE_CATEGORY, plateDataHelper.plateCategory);
                form.change(formFieldNames.PLATE_NUMBER_LATIN, plateDataHelper.plateNumber);
                form.change(formFieldNames.PLATE_NUMBER, plateDataHelper.plateNumber);
                form.change(formFieldNames.CAPTCHA, null);
                form.change(formFieldNames.PLATE_PROVINCE, plateDataHelper.province);
                form.change(formFieldNames.PLATE_USAGE, plateDataHelper.usage);
                form.change(formFieldNames.PLATE_NUMBER, plateDataHelper.plateNumber);
                form.change(formFieldNames.COUNTRY_CODE, plateDataHelper.countryCode);
                form.change(genericFormFieldNames.DESIRED_PLATE_NUMBER_LATIN, plateDataHelper.desiredPlateNumberLatin);
            }else{
                form.change(formFieldNames.PLATE_CATEGORY, null);
                form.change(formFieldNames.PLATE_NUMBER, null);
                form.change(formFieldNames.PLATE_NUMBER_LATIN, null);
                form.change(formFieldNames.CAPTCHA, null);
                form.change(formFieldNames.PLATE_PROVINCE, null);
                form.change(formFieldNames.PLATE_USAGE, null);
                form.change(genericFormFieldNames.DESIRED_PLATE_NUMBER_LATIN, null);
            }
    }

    const onPlateCategoryChange = (e) => {
        handleCommonService.handleGetSingleDictionary(projectDictionaries.PLATE_USAGE, e?.target?.value, projectDictionaries.PLATE_USAGE)
        form.change(formFieldNames.PLATE_NUMBER, null);
        form.change(formFieldNames.CAPTCHA, null);
        form.change(formFieldNames.PLATE_PROVINCE, null);
        form.change(formFieldNames.PLATE_USAGE, null);
        form.change(genericFormFieldNames.DESIRED_PLATE_NUMBER_LATIN, null);
        form.change(formFieldNames.PLATE_COUNTRY_CODE, null)
    }

    let desiredPlateCss = isOperationModeProtected() ? "flex justify-content-center protectedWrap" : "publicWrap"
    let desiredPlateNumberSizeCss = isOperationModeProtected() ? "col-4" : "col-6"
    let plateReservationButtonCss = isOperationModeProtected() ? "col-12 flex justify-content-center" : "col-6 flex justify-content-center"


    const prepareAssignedPlateNumber = (assignedPlateNumber) => {
        let provinceCode = formValuesPlateData[formFieldNames.PLATE_PROVINCE]
        let pn = assignedPlateNumber
        if(assignedPlateNumber.substring(0, 1).match(/[A-Z]/)){
            pn = provinceCode + " " + assignedPlateNumber.substring(0, 1) + " " + removeLeadingZero(assignedPlateNumber.substring(1))
        }

        form.change(formFieldNames.PLATE_NUMBER, pn)
    }

    const onAssignedPlateNumberChange = (e) => {
        if(e.target.value || e.target.value !== ""){
            dispatch(addToAppStore('checkAvailablePlateNumberButton', true))
            let desiredPlateRegex = /^[A-Za-z][0-9]*$/;

            if(e.target.value.length > 1 && e.target.value.toString().match(desiredPlateRegex)){
                prepareAssignedPlateNumber(e.target.value)
                dispatch(addToAppStore('reservePlateNumberButton', true))
            }else{
                dispatch(addToAppStore('reservePlateNumberButton', false))
            }
        }else{
            form.change(formFieldNames.PLATE_NUMBER, null);
            form.change(formFieldNames.CAPTCHA, null);
            dispatch(addToAppStore('checkAvailablePlateNumberButton', false))
            dispatch(addToAppStore('reservePlateNumberButton', false))
        }
    }


    return (
        <div className="card">
            <Messages ref={guidanceMessage1}/>
            {
                useCaseFromStore && docTypeFromStore && <div className={"useCaseHeader"}>
                    <h5>{I18n.t("label.useCaseHeader", {
                        documentType: I18n.t("label." + docTypeFromStore),
                        useCase: I18n.t("label." + useCaseFromStore)
                    })}</h5></div>
            }



            {(isModificationVRUseCase(formValues) &&
                    !disablePlateHandlingTask({...formValues, ...formValuesPlateData}, vehicleFromSystem, userRights) &&
                    isOperationModeNotProtected()) &&
                <>
                    <h5><Translate value="label.modifyPlateSite"/></h5>
                    <hr className="sectionSeparator"/>
                    <Messages ref={guidanceMessageModifyProvince}/>
                    <div className="grid">
                        <ModifyProvinceSiteData {...modifyProvinceSiteDataSettings} />
                    </div>
                </>
            }

            <>
                <h5><Translate value="label.plateInformation"/></h5>
                {renderFurtherUseSection(formValues) &&
                    <div>
                        <hr className="sectionSeparator"/>
                        <div className="radionInlineWrapForQuest">
                            <FormElement
                                label={I18n.t('label.selectSourcePlate')}
                                fieldType={fieldType.RADIO_INLINE}
                                fieldProps={{
                                    className: "radioQuest",
                                    name: 'sourcePlate',
                                    options: configurationData.filterPlateReservation ? filteredSourcePlateSelect() : sourcePlateSelect(),
                                    onChange: onPlateTypeChange
                                }}
                            />
                        </div>
                    </div>
                }

                {(!renderFurtherUseSection(formValues) || isSourcePlateChosen(formValuesPlateData, plateDataHelper)) &&
                    <div>
                        <div>
                            <hr className="sectionSeparator"/>
                            <div className="radionInlineWrapForQuest">
                                <FormElement
                                    label={I18n.t('label.selectPlateCategory')}
                                    fieldType={fieldType.RADIO_INLINE}
                                    fieldProps={{
                                        className: "radioQuest",
                                        name: formFieldNames.PLATE_CATEGORY,
                                        readOnly: desiredPlateNumberReserved || disablePlateHandlingTask({...formValues, ...formValuesPlateData}, vehicleFromSystem, userRights) || plateDataHelper,
                                        options: getPlateCategoryValues(),
                                        onChange: onPlateCategoryChange
                                    }}
                                />
                            </div>
                        </div>

                        {!configurationData?.enablePlateHandling &&
                            <div className="grid">
                                {sectionsToRender.includes(VEHICLE_DATA_STEP.PLATE_DATA) &&
                                    <PlateData {...plateDataSettings} />}
                                {(isUseCaseChangeOwnership(formValues) && formValues?.["changeOwnershipSubUseCase"] === changeOwnershipSubUseCases.SALE_AND_BUY &&
                                        !plateDataHelper && formValuesPlateData["sourcePlate"] === "FURTHER_USE") &&
                                    <PlateNumberData {...plateNumberDataSettings} />
                                }
                            </div>
                        }

                        {configurationData?.enablePlateHandling &&
                            <div className="grid">
                                {sectionsToRender.includes(VEHICLE_DATA_STEP.PLATE_DATA) &&
                                    <PlateData {...plateDataSettings} />}
                                {usePlateDataFromSystemForUCs(formValues[formFieldNames.USE_CASE]) &&
                                    <PlateNumberData {...plateNumberDataSettings} />
                                }
                            </div>
                        }

                        {/*TODO: Refactor required - create TemporaryReservation component.*/}
                        {(shouldRenderTemporaryReservation && [EUseCases.RESERVE_PLATE_NUMBER, EUseCases.RE_REGISTER].includes(useCaseFromStore)) &&
                            <>
                                <h5><Translate value="label.temporaryReservation"/></h5>
                                <hr className="sectionSeparator"/>
                                <TabView>
                                    <TabPanel header={I18n.t("action.desiredPlates")}>
                                        {isOperationModeNotProtected() && isUseCaseReRegisterVehicle(formValues) &&
                                            <div className={desiredPlateCss}>
                                                <div className={desiredPlateNumberSizeCss}>
                                                    {formValuesPlateData[formFieldNames.PLATE_CATEGORY] !== "special" &&
                                                        <FormElement
                                                            label={"label.assignedPlateNumber"}
                                                            fieldType={fieldType.INPUT_TEXT}
                                                            upperCase={true}
                                                            fieldProps={{
                                                                name: "assignedPlateNumber",
                                                                maxLength: 6,
                                                                keyfilter: "alphanum",
                                                                readOnly: desiredPlateNumberReserved,
                                                                onChange: onAssignedPlateNumberChange
                                                            }}
                                                        />}
                                                </div>

                                            </div>}
                                        {displayDesiredPlateNumber &&
                                            <div className={desiredPlateCss}>
                                            <div className={desiredPlateNumberSizeCss}>
                                                {formValuesPlateData[formFieldNames.PLATE_CATEGORY] !== "special" &&
                                                    <FormElement
                                                        label={"label.desiredPlateNumber"}
                                                        fieldType={fieldType.INPUT_TEXT}
                                                        upperCase={true}
                                                        fieldProps={{
                                                            name: genericFormFieldNames.DESIRED_PLATE_NUMBER_LATIN,
                                                            maxLength: 6,
                                                            readOnly: desiredPlateNumberReserved
                                                        }}
                                                    />}
                                            </div>

                                        </div>}
                                        {!captchaRender &&
                                            <div className={"col-12 flex justify-content-center captchaAndPlateWrap"}>
                                                {isOperationModeNotProtected() && <div className={"col-6"}>
                                                    <Captcha
                                                        captchaURL={captchaUrl}
                                                        fieldName={formFieldNames.CAPTCHA}
                                                        onRefreshCaptcha={() => getCaptcha()}
                                                        captchaPlaceholder={false}
                                                    />
                                                </div>}
                                                <div className={"col-6"}>
                                                    <div className={desiredPlateCss}>
                                                        {sectionsToRender.includes(VEHICLE_DATA_STEP.PLATE_DATA) &&
                                                            <PlateNumberData {...plateNumberDataSettings} />}
                                                    </div>
                                                </div>
                                            </div>}
                                        <div className={plateReservationButtonCss}>
                                            <div className="col-4 checkPlateBtn">
                                                <Button label={I18n.t("action.checkPlateReservation")}
                                                        disabled={checkAvailablePlateNumberButton}
                                                        className="p-button-success"
                                                        type="button"
                                                        onClick={() => checkPlateReservation()}/>
                                            </div>
                                            <div className="col-4 checkPlateNum">
                                                <Button label={I18n.t("action.reservePlateNumber")}
                                                        disabled={!reservePlateNumberButton}
                                                        className="p-button-secondary"
                                                        type="button"
                                                        onClick={() => reservePlateNumber()}/>
                                            </div>
                                            {isOperationModeProtected() &&
                                                <div className="col-4 checkPlateNum">
                                                <Button label={I18n.t("action.resetPlateReservation")}
                                                        disabled={isDisabledUnreservePlate}
                                                        className="p-button-blue"
                                                        type="button"
                                                        onClick={() => unreservePlateNumber()}/>
                                            </div>}
                                        </div>
                                    </TabPanel>

                                    {isUseCaseReRegisterVehicle(formValues) &&
                                        <TabPanel header={I18n.t("action.oldPlates")}>
                                            <OldPlateTask formName={formName}/>
                                        </TabPanel>}
                                    {isOperationModeProtected() && isUseCaseReRegisterVehicle(formValues) &&
                                        <TabPanel header={I18n.t("label.useExistingPlateReservationDataSection")}>
                                            <UseExistingPlateTask formName={formName}/>
                                        </TabPanel>
                                    }
                                </TabView>
                            </>
                        }
                    </div>
                }
            </>
        </div>
    )
};
export default PlateDataTask;