import {
  getFormValues, getFromAppStore, prepareDictionaryElementForDropdown
} from "@mb-react/mb-react-core"
import { formFieldNames,  } from "@mb-react/svarog-ui"
import { useSelector} from "react-redux"
import { formFieldsMeta, forms } from "../../../../../../constants/form"
import {
  getAccountLegalEntityType,
  isLoggedAccountLegalEntity,
  isMandatory,
  isReadOnly,
  prepareLETypeForDropDownList
} from "../../../../../../util/businesslogicUtil"
import {useCallback, useMemo} from "react";
import {projectDictionaries} from "../../../../../../constants/dictionaries";
import { genericFormFieldNames } from "@mb-react/krg-getid-form-data-common"
import {useForm} from "react-final-form";
import {prepareSpecificDictionaryElementForDropdown} from "../../../../../../util/dataPreparationUtil";
import {COMPANY, EMBASSY, GOVERNMENT, NO, IO, plateCategories} from "../../../../../../constants/application";
import debounce from "lodash.debounce";

export const useOldPlateDataSettings = () => {
  const formValues = useSelector((state) => getFormValues(state, forms.APPLICATION))
  const form = useForm();
  let plateDataFormValues = form?.getState()?.values ?? {}
  const successfullyReRegisterOldPlateNumber = useSelector((state) => getFromAppStore(state, "SUCCESSFULLY_RE_REGISTER_OLD_PLATE_NUMBER")) ?? false;

  const plateUsageOptions = useSelector((state) => prepareSpecificDictionaryElementForDropdown(state, projectDictionaries.PLATE_USAGE));
  const plateCategoryOptions = useSelector((state) => prepareSpecificDictionaryElementForDropdown(state, projectDictionaries.PLATE_CATEGORY));

  const onPlateCategoryChange = useCallback(debounce(event => {
        form.change(formFieldNames.OLD_PLATE_USAGE, null)
        handleCommonServices.handleGetSingleDictionary(projectDictionaries.PLATE_USAGE, event?.target?.value, projectDictionaries.OLD_PLATE_USAGE);
      }, 400), []
  );

  const preparePlateCategoryOptionsByApplicantType = () => {
    let naturalPersonOptionList = [plateCategories.STANDARD, plateCategories.MOTORCYCLE]
    let embassyAndInternationalOrgOptionList = [plateCategories.SPECIAL]
    let nationalOrgOptionList = [plateCategories.OTHER, plateCategories.MOTORCYCLE]
    let govAndComOptionList = [plateCategories.OTHER, plateCategories.STANDARD, plateCategories.MOTORCYCLE]
    let preparedOptions = plateCategoryOptions;
    if (isLoggedAccountLegalEntity()) {
      switch (prepareLETypeForDropDownList(getAccountLegalEntityType())) {
        case COMPANY:
        case GOVERNMENT:
          preparedOptions = plateCategoryOptions.filter(e => govAndComOptionList.includes(e.value));
          break;
        case EMBASSY:
        case IO:
          preparedOptions = plateCategoryOptions.filter(e => embassyAndInternationalOrgOptionList.includes(e.value));
          break;
        case NO:
          preparedOptions = plateCategoryOptions.filter(e => nationalOrgOptionList.includes(e.value));
          break;
      }
    } else {
      preparedOptions = plateCategoryOptions.filter(e => naturalPersonOptionList.includes(e.value))
    }
    return preparedOptions;
  }

  const oldPlateTypeSettings = useMemo(() => {
    let plateDataProps = {
      [genericFormFieldNames.OLD_PLATE_TYPE]: {
        [formFieldNames.FIELD_PROPS]: {
          maxLength: 6
        },
        exclude: true,
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return plateDataProps
  }, [successfullyReRegisterOldPlateNumber])

  const oldPlateNumberSettings = useMemo(() => {
    let plateDataProps = {
      [genericFormFieldNames.OLD_PLATE_NUMBER]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: successfullyReRegisterOldPlateNumber || isReadOnly(plateDataFormValues, genericFormFieldNames.OLD_PLATE_NUMBER, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
          maxLength: 6,
          keyfilter: "pint"
        },
        exclude: true,
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return plateDataProps
  }, [successfullyReRegisterOldPlateNumber])

  const oldPlateNumberLatinSettings = useMemo(() => {
    let plateDataProps = {
      [genericFormFieldNames.OLD_PLATE_NUMBER_LATIN]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: successfullyReRegisterOldPlateNumber || isReadOnly(plateDataFormValues, genericFormFieldNames.OLD_PLATE_NUMBER_LATIN, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
          maxLength: 6,
          keyfilter: "pint",
          digitsOnly: true
        },
        required: isMandatory(plateDataFormValues, genericFormFieldNames.OLD_PLATE_NUMBER_LATIN, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return plateDataProps
  }, [successfullyReRegisterOldPlateNumber])

  const oldPlateCategorySettings = useMemo(() => {
    let plateDataProps = {
      [genericFormFieldNames.OLD_PLATE_CATEGORY]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: successfullyReRegisterOldPlateNumber || isReadOnly(plateDataFormValues, genericFormFieldNames.OLD_PLATE_CATEGORY, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
          selectOptions: preparePlateCategoryOptionsByApplicantType(),
          showClear: true,
          [formFieldNames.ON_CHANGE]: onPlateCategoryChange
        },
        required: isMandatory(plateDataFormValues, genericFormFieldNames.OLD_PLATE_CATEGORY, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return plateDataProps
  }, [successfullyReRegisterOldPlateNumber])

  const oldPlateCountryCodeSettings = useMemo(() => {

    let plateDataProps = {
      [genericFormFieldNames.OLD_PLATE_COUNTRY_CODE]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: successfullyReRegisterOldPlateNumber || isReadOnly(plateDataFormValues, genericFormFieldNames.OLD_PLATE_COUNTRY_CODE, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
          reference: projectDictionaries.COUNTRY_CODES,
          showClear: true
        },
        required: isMandatory(plateDataFormValues, genericFormFieldNames.OLD_PLATE_COUNTRY_CODE, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return plateDataProps
  }, [plateDataFormValues[genericFormFieldNames.OLD_PLATE_CATEGORY], successfullyReRegisterOldPlateNumber])

  const oldPlateProvinceSettings = useMemo(() => {

    let plateDataProps = {
      [genericFormFieldNames.OLD_PLATE_PROVINCE]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: successfullyReRegisterOldPlateNumber || isReadOnly(plateDataFormValues, genericFormFieldNames.OLD_PLATE_PROVINCE, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
          reference: projectDictionaries.GOVERNORATE,
          showClear: true,
        },
        required: isMandatory(plateDataFormValues, genericFormFieldNames.OLD_PLATE_PROVINCE, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return plateDataProps
  }, [plateDataFormValues[genericFormFieldNames.OLD_PLATE_CATEGORY], successfullyReRegisterOldPlateNumber])


  const oldPlateUsageSettings = useMemo(() => {

    let plateCategory = plateDataFormValues[genericFormFieldNames.OLD_PLATE_CATEGORY];
    let usageOptions = plateUsageOptions;

    if(isLoggedAccountLegalEntity()){
      switch (plateCategory) {
        case plateCategories.MOTORCYCLE:
          usageOptions = plateUsageOptions.filter(e => ["private", prepareLETypeForDropDownList(getAccountLegalEntityType())].includes(e.value))
          break;
        case plateCategories.OTHER:
          usageOptions = plateUsageOptions.filter(e => e.value === prepareLETypeForDropDownList(getAccountLegalEntityType()))
          break;
        case plateCategories.STANDARD:
          if(getAccountLegalEntityType() === "GOVERNMENT"){
            usageOptions = plateUsageOptions.filter(e => e.value === "private")
          }
          break;
      }
    }else{
      if(plateDataFormValues[formFieldNames.PLATE_CATEGORY] === "motorcycle"){
        usageOptions = plateUsageOptions.filter(e => e.value === "private")
      }
    }

    let plateDataProps = {
      [genericFormFieldNames.OLD_PLATE_USAGE]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: successfullyReRegisterOldPlateNumber || isReadOnly(plateDataFormValues, genericFormFieldNames.OLD_PLATE_USAGE, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
          selectOptions: usageOptions,
          showClear: true,
          //[formFieldNames.ON_CHANGE]: onUsageChange
        },
        required: isMandatory(plateDataFormValues, genericFormFieldNames.OLD_PLATE_USAGE, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return plateDataProps
  }, [plateUsageOptions, successfullyReRegisterOldPlateNumber])

  const oldPlateSerialNumberSettings = useMemo(() => {
    let plateDataProps = {
      [genericFormFieldNames.OLD_PLATE_SERIAL_NUMBER]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: successfullyReRegisterOldPlateNumber || isReadOnly(plateDataFormValues, genericFormFieldNames.OLD_PLATE_SERIAL_NUMBER, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
          maxLength: 3
        },
        exclude: true,
        required: isMandatory(plateDataFormValues, genericFormFieldNames.OLD_PLATE_SERIAL_NUMBER, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return plateDataProps
  }, [successfullyReRegisterOldPlateNumber])

  const oldPlateSerialNumberLatinSettings = useMemo(() => {
    let plateDataProps = {
      [genericFormFieldNames.OLD_PLATE_SERIAL_NUMBER_LATIN]: {
        [formFieldNames.FIELD_PROPS]: {
          readOnly: successfullyReRegisterOldPlateNumber || isReadOnly(plateDataFormValues, genericFormFieldNames.OLD_PLATE_SERIAL_NUMBER_LATIN, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
          maxLength: 3
        },
        required: isMandatory(plateDataFormValues, genericFormFieldNames.OLD_PLATE_SERIAL_NUMBER_LATIN, formValues[formFieldNames.USE_CASE], formValues[formFieldNames.DOCUMENT_TYPE], formValues[formFieldsMeta.WORKFLOW]),
        additionalClass: "col-12 md:col-4 xl:col-4"
      }
    }

    return plateDataProps
  }, [successfullyReRegisterOldPlateNumber])

  return {...oldPlateTypeSettings, ...oldPlateNumberSettings, ...oldPlateNumberLatinSettings, ...oldPlateCategorySettings, ...oldPlateCountryCodeSettings, ...oldPlateProvinceSettings,
        ...oldPlateUsageSettings, ...oldPlateSerialNumberSettings, ...oldPlateSerialNumberLatinSettings}
}