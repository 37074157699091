import {genericFormFieldNames} from "@mb-react/krg-getid-form-data-common";
import {formFieldNames} from "@mb-react/svarog-ui";

export const specificMandatoryBehaviorFieldList = [
    formFieldNames.PLACE_OF_BIRTH_PROVINCE,
    formFieldNames.PLACE_OF_BIRTH_CITY,
    genericFormFieldNames.PLACE_OF_BIRTH_VILLAGE,

    formFieldNames.SPOUSE_NAME,
    genericFormFieldNames.SPOUSE_FATHER_NAME,
    genericFormFieldNames.SPOUSE_GRANDFATHER_NAME,
    genericFormFieldNames.CATERING_CENTER_NUMBER_NAME,
    genericFormFieldNames.CATERING_CENTER_PROVINCE,
    genericFormFieldNames.FOOD_RATION_CARD_NUMBER,
    genericFormFieldNames.FOOD_RATION_CARD_ISSUE_DATE,

    formFieldNames.ADDRESS_STREET_NUMBER,
    formFieldNames.ADDRESS_HOUSE_NUMBER,
    genericFormFieldNames.QUARTER_NAME,
    genericFormFieldNames.SECTOR_NUMBER,
    genericFormFieldNames.VILLAGE_REGION,
    genericFormFieldNames.RESIDENCE_CARD_NUMBER,
    genericFormFieldNames.RESIDENCE_CARD_DATE_OF_ISSUE,
    genericFormFieldNames.RESIDENCE_CERTIFICATE_NUMBER,
    genericFormFieldNames.RESIDENCE_CERTIFICATE_DATE_OF_ISSUE,
    genericFormFieldNames.RESIDENCE_PERMIT_ISSUE_DATE,
    genericFormFieldNames.RESIDENCE_PERMIT_NUMBER,

    genericFormFieldNames.PROVINCE_OF_REGISTRATION,
    genericFormFieldNames.CITY_OF_REGISTRATION,
    genericFormFieldNames.VILLAGE_OF_REGISTRATION,
    genericFormFieldNames.REGISTRATION_BOOK_NUMBER,
    genericFormFieldNames.REGISTRATION_PAGE_NUMBER,
    formFieldNames.EMPLOYER_OR_SCHOOL,

    formFieldNames.REASON_FOR_PAYMENT_EXEMPTION,
    formFieldNames.FREE_OF_CHARGE,

    formFieldNames.OWNERSHIP_PERCENTAGE,
    genericFormFieldNames.NUMBER_OF_REQUESTED_PLATES,
    genericFormFieldNames.EXISTING_PLATES,
    genericFormFieldNames.PREVIOUS_CANCELLATION_COMMENT,
    genericFormFieldNames.TEMPORARY_CANCELLATION_NUMBER_OF_MONTHS,

    genericFormFieldNames.REQUEST_APPROVAL_COMMENT,

    // vehicle
    formFieldNames.VEHICLE_BRAND,
    formFieldNames.VEHICLE_MODEL,
    formFieldNames.PRODUCTION_YEAR,
    formFieldNames.VEHICLE_COLOR,
    formFieldNames.SEATS,
    formFieldNames.FUEL,
    formFieldNames.CYLINDERS,
    formFieldNames.CABIN_NUMBER,
    formFieldNames.CABIN_TYPE,
    formFieldNames.TOTAL_WEIGHT,

    // previous vr number
    genericFormFieldNames.OLD_VEHICLE_REGISTRATION_NUMBER,

    //legalEntity
    formFieldNames.LE_TPID,
    formFieldNames.LE_NAME,
    formFieldNames.LE_TYPE,
    formFieldNames.LE_SUBTYPE,
    formFieldNames.TPID_PARENT,
    formFieldNames.LE_ADDRESS_COUNTRY,
    formFieldNames.LE_COUNTRY_CODE,
    formFieldNames.LE_ADDRESS_PROVINCE,
    formFieldNames.LE_ADDRESS_CITY,
    formFieldNames.LE_ADDRESS_REGION,
    genericFormFieldNames.LE_ADDRESS_VILLAGE,
    formFieldNames.LE_ADDRESS_STREET,
    formFieldNames.LE_ADDRESS_HOUSE_NUMBER,
    formFieldNames.LE_ADDRESS_ZIP_CODE,
    formFieldNames.LE_QUARTER_NAME,
    formFieldNames.LE_QUARTER_NAME,
    formFieldNames.LE_SECTOR_NUMBER,
    formFieldNames.LE_STATE,
    genericFormFieldNames.AXIS,
    genericFormFieldNames.CONTRACT_DATE,
    genericFormFieldNames.CONTRACT_NUMBER
];

export const specificReadOnlyBehaviorFieldList = [
    formFieldNames.PLACE_OF_BIRTH_PROVINCE,
    formFieldNames.PLACE_OF_BIRTH_CITY,
    genericFormFieldNames.PLACE_OF_BIRTH_VILLAGE,

    formFieldNames.SPOUSE_NAME,
    genericFormFieldNames.SPOUSE_FATHER_NAME,
    genericFormFieldNames.SPOUSE_GRANDFATHER_NAME,

    genericFormFieldNames.CATERING_CENTER_NUMBER_NAME,
    genericFormFieldNames.CATERING_CENTER_PROVINCE,
    genericFormFieldNames.FOOD_RATION_CARD_NUMBER,
    genericFormFieldNames.FOOD_RATION_CARD_ISSUE_DATE,
    genericFormFieldNames.FOOD_CENTER_INDICATOR,

    formFieldNames.ADDRESS_STREET_NUMBER,
    formFieldNames.ADDRESS_HOUSE_NUMBER,
    genericFormFieldNames.QUARTER_NAME,
    genericFormFieldNames.SECTOR_NUMBER,
    genericFormFieldNames.ELDER_NAME,
    genericFormFieldNames.VILLAGE_REGION,
    genericFormFieldNames.RESIDENCE_CARD_NUMBER,
    genericFormFieldNames.RESIDENCE_CARD_DATE_OF_ISSUE,
    genericFormFieldNames.RESIDENCE_CERTIFICATE_NUMBER,
    genericFormFieldNames.RESIDENCE_CERTIFICATE_DATE_OF_ISSUE,
    genericFormFieldNames.RESIDENCE_PERMIT_ISSUE_DATE,
    genericFormFieldNames.RESIDENCE_PERMIT_NUMBER,

    genericFormFieldNames.PROVINCE_OF_REGISTRATION,
    genericFormFieldNames.CITY_OF_REGISTRATION,
    genericFormFieldNames.VILLAGE_OF_REGISTRATION,
    genericFormFieldNames.REGISTRATION_BOOK_NUMBER,
    genericFormFieldNames.REGISTRATION_PAGE_NUMBER,

    formFieldNames.REASON_FOR_PAYMENT_EXEMPTION,
    formFieldNames.FREE_OF_CHARGE,

    formFieldNames.OWNERSHIP_PERCENTAGE,
    formFieldNames.OWNERSHIP_LEAD,
    genericFormFieldNames.NUMBER_OF_REQUESTED_PLATES,
    genericFormFieldNames.EXISTING_PLATES,
    genericFormFieldNames.EXISTING_PLATE_INPUT,
    genericFormFieldNames.PREVIOUS_CANCELLATION_COMMENT,
    genericFormFieldNames.TEMPORARY_CANCELLATION_NUMBER_OF_MONTHS,

    genericFormFieldNames.REQUEST_APPROVAL_COMMENT,

    // vehicle
    formFieldNames.VEHICLE_SUBCATEGORY,

    // plate registration data
    genericFormFieldNames.DESIRED_PLATE_NUMBER_LATIN,
    genericFormFieldNames.AVAILABLE_PLATE_NUMBER_LATIN,
    formFieldNames.DAYS_TO_EXTEND_PLATE_RESERVATION,

    // previous vr number
    genericFormFieldNames.OLD_VEHICLE_REGISTRATION_NUMBER,

    formFieldNames.LE_SUBTYPE
];
